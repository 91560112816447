@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200&family=Handjet:wght@300&family=Lato:wght@300;400&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Nunito:wght@200&family=Open+Sans:wght@300&family=Roboto:wght@100;300;400&display=swap');

* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

img {
  pointer-events: none;
}

#root
{
  overflow-x: hidden;
}

#firstSection
{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background: url("../graphics/main_slider.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.nonAvailable
{
  padding: 10px;
  color: red;
  font-weight: 350;
  font-size: 20px;
  border: 1px solid red;
  text-align: center;
}

@media (max-width: 1000px) {
  #headerCentered
  {
    margin-top: 5px;
  }
  h1 {
    font-size: 38px;
    color: #081424;
    font-weight: 500;
  }
  
  h2 {
    font-size: 32px;
    color: #081424;
    font-weight: 700;
  }
  h3 {
    font-size: 22px;
    color: #081424;
    font-weight: 600;
  }
  #menu
  {
    overflow: hidden;
    transition: all 0.3s ease-out;
    max-height: 0;
  }
  #menu.fixed
  {
    max-height: 240px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  #menu.block
  {
    color: white;
  }
  #headerLogoBox
  {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  #mobile-menu-button
  {
    margin-right: 20px;
  }
  #header {
    transition: height 0.4s ease, background-color 0.4s ease, border-bottom 0.4s ease; 
    overflow: hidden;
    z-index: 1;
    width: 100%;
    min-height: 50px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    background-color: #081424;
  }
  #header.fixed {
    position: fixed;
    min-height: 50px;
    background-color: #ffffff;
    box-shadow: 0 -3px 5px 3px rgba(202, 200, 200, 0.5);
  }

#mainHeaderLogo {
  width: 120px;
  margin-left: 20px;
}

#menu
{
  flex-direction: column;
  list-style-type: none;
  gap: 5px;
}

#mobile-menu-button {
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#mobile-menu-button:hover {
  cursor: pointer;
}

.line {
  width: 100%;
  height: 4px;
  transition: transform 0.3s ease;
}

.menuButtonLine
{
  background-color: #fffefe;
}
.menuButtonLine.fixed {
  background-color: #081424;
}


#mobile-menu-button.fixed .line:nth-child(1) {
  transform: translateY(15px) rotate(45deg);
}

#mobile-menu-button.fixed .line:nth-child(2) {
  opacity: 0;
}

#mobile-menu-button.fixed .line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}
#headerLanguageBox
{
  justify-content: center;
}

}

@media (max-width: 1250px) {
  .footerCentered
  {
    gap: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1250px ) {
  .footerCentered
  {
    gap: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media (min-width: 1000px) {
  h1 {
    font-size: 56px;
    color: #081424;
    font-weight: 500;
  }
  
  h2 {
    font-size: 45px;
    color: #081424;
    font-weight: 700;
  }
  
  h3 {
    font-size: 28px;
    color: #081424;
    font-weight: 600;
  }
  #mobile-menu-button
  {
    display: none;
  }
  #header {
    transition: height 0.4s ease, background-color 0.4s ease, border-bottom 0.4s ease; 
    z-index: 1;
    width: 100%;
    height: 120px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #081424;
  }
  #header.fixed {
    position: fixed;
    height: 70px;
    background-color: #ffffff;
    box-shadow: 0 -3px 5px 3px rgba(202, 200, 200, 0.5);
  }
  #mainHeaderLogo {
    max-height: 60px;
  }
  #mainHeaderLogo.fixed {
    max-height: 45px;
  }
  #headerCentered
  {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  #menu
  {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    text-align: center;
    gap: 30px;
  }
  
  }
  


.menuItem
{
  font-size: 14px;
  user-select: none;
  font-weight: 500;
  color: white;
}
.menuItem.fixed 
{
  color: black;
}

.menuItem:hover
{
  color: #69dbe1;
  cursor: pointer;
}

.whiteText
{
  color: white;
}

h4 {
  font-size: 22px;
  font-weight: 500;
  color: #081424;
}

.footer
{
  margin-top: 50px;
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #081424;
  border-top: 7px solid rgb(56, 97, 137);
}
.footerLogo
{
  margin: 25px;
  max-height: 100px;
}

.footerBox
{
  display: flex;
  width: 180px;
  flex-direction: column;
  gap: 20px;
}

.line
{
  display: flex;
  flex-direction: row;
}

.line1
{
  width: 33.33%;
}

.footerText {
  color: white;
  font-size: 15px;
}

.footerIcon
{
  max-height: 15px;
}

.footerLink
{
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-decoration: none;
  color: white;
  font-size: 15px;
}
.footerLink:hover
{
  color: #69dbe1;
}

.pageTitle
{
  padding-bottom: 125px;
}

#circleBox
{
  width: 100%;
  height: 60px;
  position: relative;
  text-align: center;
}

#middleCircle
{
  position: relative;
  bottom: 30px;
  max-height: 60px;
}

li {
  margin-top: 5px;
}

a {
  text-decoration: none;
}
.pageTopNav
{
  flex-wrap: wrap;
  margin-bottom: 30px;
  position: relative;
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 16px;
}
.pageTopNavHref {
  font-weight: 400;
  text-decoration: none;
  color: rgb(50, 4, 136);
  font-size: 17px;
}

.pageTopNavHref:hover {
  cursor: pointer;
  color: rgb(68, 67, 67);
}
.pageTopNavSepetator
{
  color: rgb(41, 40, 40);
  font-size: 16px;
}

.priacyPolicy-bold
{
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.privacyPolicy-centeredBox
{
  width: 50%;
  text-align: justify;
}

.privacyPolicy-list
{
  margin-top: 20px;
}

.boldText
{
  font-weight: 500;
}

.siteInDevelopment {
  font-size: 30px;
  rotate: -45deg;
  position: absolute;
  font-weight: 400;
  color: red;
}

.a {
  background-color: #565757;
}

#headerLanguageBox
{
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}

.headerFlag
{
  max-height: 30px;
}
.flagBox
{
  opacity: 0.9;
}
.flagBox:hover
{
  opacity: 1;
  cursor: pointer;
}