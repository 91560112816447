.productCategories-secondSection {
  width: 100%;
  background: url('../graphics/logo-sign-n.png');
  background-attachment: fixed;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    gap: 50px;
}

.productCategories-textParagraphBox
{
    width: 40%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 30px;
    margin-bottom: 30px;
}

.productCategories-textParagraphRowBox
{
    max-width: 70%;
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 30px;
}

.productCategories-productBox 
{
    width: 280px;
    height: 300px;
    font-size: 16pt;
    color: #081424;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(195, 193, 193);
    transition: all 0.3s ease;
}
.productCategories-productBox:hover
{
    transform: scale(1.03); 
    opacity: 0.95;
    cursor: pointer;
}   
.productCategories-productBox:hover > .productCategories-productName
{
    position: relative;
}
.productCategories-productName
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 16px;
    color:white;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
} 

.productCategories-productGroupName
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 16px;
    color:white;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
} 

.productCategories-productBox:hover
{
    cursor: pointer;
    opacity: 0.7;
}

.productCategories-productMiniature
{
    min-height: 50%;
  max-width: 100%;
  max-height: 100%;
}

.productCategories-productTitle
{
    padding: 5px;
    font-size: 12pt;
    font-weight: 600;
}

.productCategories-productCategoryTitle
{
    font-size: 26pt;
}

#productCategories-productsGrid
{
    margin-top: 10px;
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    gap: 50px;
    margin-bottom: 50px;
}
.productCategories-productImage
{
    max-width: 200px;
}

.productCategories-descriptionAndParameters
{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 70px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.productCategories-description
{
    text-align: justify;
    width: 400px;
    display: flex;
    font-size: 16px;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}

.productCategories-parameters
{
    width: 300px;
}

.productCategories-productParameters
{
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border-radius: 10px;
    border: 1px solid black;
    margin-bottom: 50px;
}

.productParametersIcon
{
    max-height: 50px;
}

.productCategories-secondSectionList
{
    max-width: 300px;
    font-size: 15px;
}

.productCategories-productParametersBox
{
    width: 200px;
    text-wrap: wrap;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.productCategories-topImage
{
    width: 600px;
}

.productCategories-productBoxImgContainer
{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.productCategories-productPageContainer
{
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    margin-bottom: 50px;
    gap: 50px;
}

.productCategories-productDescription
{
    width: 70%;
    text-wrap: wrap;
    text-align: justify;
    font-size: 16px;
    color: #081424;
}
.productCategories-productDescriptionTitle {
    padding-right: 8px;
    font-size: 22pt;
}

.productCategories-textParagraph
{
    font-size: 16px;
    text-align: justify;
}

.productCategories-productGroupDescription
{
    width: 50%;
    text-wrap: wrap;
    text-align: justify;
    font-size: 16px;
    color: #081424;
}

#productCategories-productCategoriesBigBox
{
    display: flex;
    gap: 50px;
    flex-direction: row;
    margin-bottom: 50px;
}
.productCategories-uvaTile
{
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 380px;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    
}
.productCategories-uvaTile:hover
{
    opacity: 0.8;
    cursor: pointer;
}

.productCategories-productCategoriesBigBoxTop
{
    margin-top: 40px;
    border-radius: 10px;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.productCategories-productCategoriesBigBoxBottom
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
}

.productCategories-productCategoriesBigBoxImg
{
    max-height: 130px;
}

.productCategories-productImageWithDescription
{
    text-align: center;
}

#productCategories-xaraMiniature
{
    max-height: 70%;
}

.productSlider
{
  width: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#productSlider-img
{
    max-width: 100%;
    max-height: 100%;
}

.sliderController
{
    transform: translateY(-50%);
    background-color: #081424;
    color: #fff;
    padding: 5px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    border-radius: 30px;
    padding: 10px;
}
.sliderController:hover
{
    background-color: #0c1f38;
}

.productSliderBox
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 490px;
}

.productCategories-airPaintMount
{
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
}

.airPaintM
{
    width: 150px;
}

.productCategories-UVA {
    -webkit-box-shadow: 0px 0px 21px -11px rgb(242, 255, 60);
    -moz-box-shadow: 0px 0px 21px -11px rgb(242, 255, 60);
    box-shadow: 0px 0px 21px -11px rgb(242, 255, 60);
}

.productCategories-UVC {

    -webkit-box-shadow: 0px 0px 21px -11px rgba(12, 123, 235, 1);
-moz-box-shadow: 0px 0px 21px -11px rgba(12, 123, 235, 1);
box-shadow: 0px 0px 21px -11px rgba(12, 123, 235, 1);
}
.productCategories-Agri {
    -webkit-box-shadow: 0px 0px 21px -11px rgb(230, 152, 27);
    -moz-box-shadow: 0px 0px 21px -11px rgb(230, 152, 27);
    box-shadow: 0px 0px 21px -11px rgb(230, 152, 27);
}

.productCategories-Human {
    -webkit-box-shadow: 0px 0px 21px -11px rgb(212, 78, 78);
    -moz-box-shadow: 0px 0px 21px -11px rgb(212, 78, 78);
    box-shadow: 0px 0px 21px -11px rgb(212, 78, 78);
}

.productCategories-lamps
{
    -webkit-box-shadow: 0px 0px 21px -11px rgb(238, 194, 138);
    -moz-box-shadow: 0px 0px 21px -11px rgb(238, 194, 138);
    box-shadow: 0px 0px 21px -11px rgb(238, 194, 138);
}

.productCategories-controllers
{
    -webkit-box-shadow: 0px 0px 21px -11px rgb(170, 247, 170);
    -moz-box-shadow: 0px 0px 21px -11px rgb(170, 247, 170);
    box-shadow: 0px 0px 21px -11px rgb(170, 247, 170);
}

.productCategories-chargers
{
    -webkit-box-shadow: 0px 0px 21px -11px rgb(166, 223, 252);
    -moz-box-shadow: 0px 0px 21px -11px rgb(166, 223, 252);
    box-shadow: 0px 0px 21px -11px rgb(166, 223, 252);
}
.productCategories-pictureWithDescription
{
    text-align: center;
}
