#odm-secondSection 
{
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../graphics/logo-sign-n.png');
  opacity: 0.92;
  background-attachment: fixed;
  margin-bottom: 30px;
}
#odm-secondSectionCentered
{
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
}

#odm-secondSectionLogo
{
  width: 300px;
}

#odm-thirdSection
{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    background-color: #e7e7e7ad;
}

.odm-thirdSectionBox
{
    padding: 30px;
    width: 60%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 80px;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}

.odm-thirdSectionTableBox
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.odm-thirdSectionTableCell6
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 150px;
    font-weight: 300;
    text-align: center;
}

.odm-thirdSectionTableIcon
{
    width: 100px;
}

.odm-firstDescription {
  text-align: justify;
  font-size: 20px;
  font-weight: "500";
  width: 63.5%;
  color: #1d1c1c;
}