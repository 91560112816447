#company-secondSection 
{
  width: 100%;
  min-height: 350px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../graphics/logo-sign-n.png');
  opacity: 0.92;
  background-attachment: fixed;
}
#company-secondSectionLogo
{
  width: 300px;
}

#company-secondSectionDescription
{
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#company-secondSectionLogoBox
{
  width: 300px;
}

.company-secondSectionParagraph
{
  min-width: 300px;
  max-width: 550px;
  font-size: 18px;
  text-align: justify;
  color: #081424;
}

#company-secondSectionCentered
{
  width: 70%;
  gaP: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
}

#company-thirdSection
{
  padding-top: 50px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
}

#company-thirdSectionCentered
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

#company-thirdSectionBoxContainer
{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
}

.company-thirdContainerBox
{
  padding-bottom: 15px;
  font-size: 13px;
  color: #081424;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
  border-radius: 10px;
  text-align: justify;
  -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
  box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}

.company-thirdContainerBoxText
{
  font-size: 16px;
  width: 80%;
}

.company-thirdSectionBoxImg
{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.company-thirdSectionBoxList
{
  font-size: 16px;
  width: 80%;
  box-sizing:border-box;
}

@media (min-width: 1000px) {
  .company-bigTitle {
    font-size: 80px;
  }
  
  .company-bigTitle2 {
    font-size: 32px;
  }
}

@media (max-width: 1000px) {
  .company-bigTitle {
    font-size: 55px;
  }
  
  .company-bigTitle2 {
    font-size: 20px;
  }
}