#contact-thirdSection
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    background-color: #e7e7e7ad;
}

#contact-secondSection
{
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: url('../graphics/logo-sign-n.png');
    opacity: 0.92;
    background-attachment: fixed;
}

.contact-contactBox
{
    padding: 30px;
    min-width: 350px;
    width: 40%;
    min-height: 600px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}

.contact-centeredBox
{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#contact-thirdSectionCentered
{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.contact-Icon
{
  max-height: 15px;
}

.contact-Link
{
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-decoration: none;
  color: #081424;;
  font-size: 16px;
  font-weight: 300;
}
.contact-Link:hover
{
    color: #69dbe1;
}

.contact-groupBox
{
    margin-top: 10px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.contact-FormInput
{
    color:#081424;
    padding: 8px;
    width: 100%;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid rgb(226, 226, 226);
}
.contact-contactFormInput:hover
{
    background-color: rgb(252, 252, 252);
}

#contact-formTextArea
{
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    resize: vertical;
    min-height: 200px;
}

#contact-contactFormTextArea:hover
{
    background-color: rgb(252, 252, 252);
}

#contact-sendFormButton
{
    width: 100%;
    height: 50px;
    font-size: 14px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #0c1e35;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
#contact-sendFormButton:hover
{
    cursor: pointer;
    opacity: 0.9;
    background-color: #102745;
}

.contact-privacyPolicyBox
{
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
#contact-formError
{
    padding: 8px;
    width: 100%;
    text-align: center;
    color: red;
    border: 1px solid red;
    font-size: 13px;
    font-weight: 400;
}

#contact-formSuccess
{
    padding: 5px;
    width: 100%;
    text-align: center;
    color: rgb(24, 225, 57);
    font-size: 18px;
    font-weight: 400;
}