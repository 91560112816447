#competences-secondSection 
{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../graphics/logo-sign-n.png');
  opacity: 0.92;
  background-attachment: fixed;
}

.competences-thirdSectionTableIcon
{
    width: 100px;
}

.competences-thirdSectionBox
{
    padding: 30px;
    width: 60%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 80px;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}

.competences-thirdSectionTableCell6
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 150px;
    text-align: center;
}

.competences-thirdSectionTableBox
{
    margin-top: 50px;
    display: flex;
    border-radius: 10px;
    padding: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}

#competences-thirdSection
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    background-color: #e7e7e7ad;
}

#competences-secondSectionCenteredBox
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}
.competences-cards
{
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 500px;
  -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
  box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
}
.competences-img
{
  width: 100%;
}
.competences-text
{
  text-align: justify;
  padding: 30px;
}