#products-secondSection
{
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background: url('../graphics/logo-sign-n.png');
  opacity: 0.92;
  background-attachment: fixed;
  padding-bottom: 200px;
}


.products-secondSectionContainer
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
}

.products-productCategoryBox
{
    box-sizing: border-box;
    position: relative;
    width: 450px;
    height: 300px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 24px -10px rgba(66, 68, 90, 1);
    background-size: 100% 100%;
    transition: all 0.3s ease;
}
.products-productCategoryBox:hover
{
    padding: 30px;
    opacity: 0.95;
    cursor: pointer;
    -webkit-box-shadow: 8px 8px 24px -6px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px -6px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px -6px rgba(66, 68, 90, 1);
}   

.products-productCategoryBoxHeader
{
    color:white;
    width: 100%;
    margin-top: 180px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
}

#products-agrijetMiniature
{
    max-width: 200px;
}

#privacyPolicy-secondSection
{
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background: url('../graphics/logo-sign-n.png');
    opacity: 0.92;
    background-attachment: fixed;
    padding-bottom: 200px;
}

